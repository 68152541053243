import "./App.css";
import Cookies from "js-cookie";
import { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// Components
import MainPage from "./components/mainPage.jsx";
import LoginForm from "./components/loginForm.jsx";

const options = {
  position: "bottom-right",
  pauseOnHover: true,
  closeOnClick: true,
  theme: "dark",
  autoClose: 2500
}

class App extends Component {
  constructor(props) {
    super(props);

    console.log(process.env.NODE_ENV);
    this.URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";

    console.log(this.URL);

    this.state = {
      loggedIn: localStorage.getItem("jwt-token") ? true : false,
      username: "",
      strategy1Instances: [],
      orders: [],
      logs: [],
      strategyStatus1: ""
    }
  }

  request = async (route, method, body, logoutOnElse=true, msgOnSuccess=true) => {
    let res;

    if (method === "POST") {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        },
        body: JSON.stringify(body)
      });
    } else {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        }
      });
    }

    const json = await res.json();
    console.log(json);

    if (json.type === "success") {
      if (msgOnSuccess) {
        toast.success(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "info") {
      if (msgOnSuccess) {
        toast.info(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "error") {
      toast.error(`Error: ${json.message}`, options);

      return json

    } else if (json.type === "generateToken") {
      console.log(`Redirecting to ${json.url}`);
      window.location = json.url;

      return json

    } else {
      toast.error(`${json.type}: ${json.message}`, options);
      if (logoutOnElse) {
        toast.error("Logged out !", options);
        this.handleLogout();
      }

      return json
    }
  }

  componentDidMount = async () => {
    if (this.state.loggedIn) {
      const json = await this.request("current-user/", "GET");

      if (json.type === "success") {
        this.setState({
          loggedIn: true,
          username: json.username
        }, () => this.fetchStrategyStatus(1));
      }
    }
  }

  getCsrfCookie = () => {
    return Cookies.get("csrftoken");
  }

  handleLogin = async data => {
    console.log(data);
    const json = await this.request("login/", "POST", data, false);

    if (json.type === "success") {
      localStorage.setItem("jwt-token", json.jwtToken);

      this.setState({
        loggedIn: true,
        username: json.username
      }, () => this.fetchStrategyStatus(1));
    }
  }

  handleLogout = () => {
    localStorage.removeItem("jwt-token");
    this.setState({loggedIn: false});
  }

  getInstances = async num => {
    const json = await this.request(`instances/${num}/`, "GET", null, true, false);

    if (json.type === "success") {
      const instances = json.data;

      if (num === 1) {
        this.setState({strategy1Instances: instances});
      }
    }
  }

  addInstance = async (state, num) => {
    await this.request(`instances/add/${num}/`, "POST", {data: state});
    this.getInstances(num);
  }

  deleteInstance = async (id, num) => {
    const json = await this.request(`instances/delete/${id}/${num}/`, "DELETE");

    if (json.type === "success") {
      await this.getInstances(num);
    }
  }

  squareoffInstance = async (id, num) => {
    const json = await this.request(`instances/close/${id}/${num}/`, "DELETE");

    if (json.type === "success") {
      await this.getInstances(num);
    }
  }

  startInstance = async (id, num) => {
    const json = await this.request(`instances/start/${id}/${num}/`, "PUT");

    if (json.type === "success") {
      await this.getInstances(num);
    }
  }

  stopInstance = async (id, num) => {
    const json = await this.request(`instances/stop/${id}/${num}/`, "PUT");

    if (json.type === "success") {
      await this.getInstances(num);
    }
  }

  getOrders = async () => {
    const json = await this.request("orders/", "GET");

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.orders = json.orders;
      return newState;
    });

    console.log(json.orders);
  }

  clearOrders = async () => {
    await this.request("orders/clear/", "DELETE");
    this.getOrders();
  }

  getLogs = async () => {
    const json = await this.request("logs/", "GET");

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.logs = json.logs;
      return newState;
    });

    console.log(json.logs);
  }

  clearLogs = async () => {
    await this.request("logs/clear/", "DELETE");
    this.getLogs();
  }

  fetchStrategyStatus = async num => {
    const json = await this.request(`strategy/status/${num}/`, "GET");

    if (json.type === "success") {
      if (json.data === 0) {
        this.setState(prevState => {
          const newState = { ...prevState };
          newState[`strategyStatus${num}`] = "running";
          return newState;
        });

      } else {
        this.setState(prevState => {
          const newState = { ...prevState };
          newState[`strategyStatus${num}`] = "stopped";
          return newState;
        });
      }
    }
  }

  startStrategy = async num => {
    const json = await this.request(`strategy/start/${num}/`, "PUT");

    if (json.type === "success") {
      this.fetchStrategyStatus(num);
    }
  }

  stopStrategy = async num => {
    const json = await this.request(`strategy/stop/${num}/`, "PUT");

    if (json.type === "success") {
      this.fetchStrategyStatus(num);
    }
  }

  render() {
    let page = null;

    if (!this.state.loggedIn) {
      page = <LoginForm
        handleLogin={this.handleLogin}
      />

    } else {
      page = <MainPage
        URL={this.URL}
        username={this.state.username}
        handleLogout={this.handleLogout}

        strategy1Instances={this.state.strategy1Instances}
        getInstances={this.getInstances}
        addInstance={this.addInstance}
        startInstance={this.startInstance}
        stopInstance={this.stopInstance}
        squareoffInstance={this.squareoffInstance}
        deleteInstance={this.deleteInstance}

        orders={this.state.orders}
        getOrders={this.getOrders}
        clearOrders={this.clearOrders}

        logs={this.state.logs}
        getLogs={this.getLogs}
        clearLogs={this.clearLogs}

        stopStrategy={this.stopStrategy}
        startStrategy={this.startStrategy}
        strategyStatus1={this.state.strategyStatus1}
        fetchStrategyStatus={this.fetchStrategyStatus}
      />
    }

    return (
      <div className="App">
        {page}
        <ToastContainer />
      </div>
    );
  }
}

export default App;