import { Component } from "react";

class Strategy1AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrip: "NIFTY",
      lots: 0,
      capital: 0,
      accBalancePct: 0,
      tradingMode: "Paper"
    }
  }

  componentDidMount() {
    let localStorageState = localStorage.getItem("jigar-forex-macd-algo-inputs-1");

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("jigar-forex-macd-algo-inputs-1", stateToSave);

    } else {
      localStorageState = JSON.parse(localStorageState);
      this.setState(localStorageState);
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  handleEdit = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("jigar-forex-macd-algo-inputs-1", stateToSave);
    });
  }

  handleAdd = async () => {
    await this.props.addInstance(this.state, 1);
    this.props.hideAddDialog();
  }

  render() {
    return (
      <div className={this.props.open ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.hideAddDialog}></div>

        <div className="modal-card is-fullwidth">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Add Instance</h5>
            <button className="delete" onClick={this.props.hideAddDialog}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Add a new instance which will run its seperate strategy</p>

            <form onSubmit={this.handleAdd}>
              <div className="field mb-3">
                <label htmlFor="scrip" className="label">Scrip:</label>
                <div className="control">
                  <input
                    id="scrip"
                    name="scrip"
                    className="input"
                    value={this.state.scrip}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="lots" className="label">Lots:</label>
                <div className="control">
                  <input
                    id="lots"
                    name="lots"
                    type="number"
                    className="input"
                    value={this.state.lots}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="capital" className="label">Capital:</label>
                <div className="control">
                  <input
                    id="capital"
                    type="number"
                    name="capital"
                    className="input"
                    value={this.state.capital}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="acc-balance-pct" className="label">Account Balance %:</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    id="acc-balance-pct"
                    name="accBalancePct"
                    onChange={this.handleEdit}
                    value={this.state.accBalancePct}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="trading-mode" className="label">Trading Mode:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="trading-mode"
                      name="tradingMode"
                      onChange={this.handleEdit}
                      value={this.state.tradingMode}
                    >
                      <option value="Paper">Paper</option>
                      <option value="Live">Live</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </section>

          <footer className="modal-card-foot">
            <button onClick={this.handleAdd} className="button is-primary">Add</button>
            <button onClick={this.props.hideAddDialog} className="button is-danger">Cancel</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default Strategy1AddDialog;
