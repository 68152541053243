import { Component } from "react";

class Strategy1InfoBox extends Component {
  render() {
    return (
      <div className={this.props.show ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.handleClose}></div>

        <div className="modal-card">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Instance Info (Strategy #1)</h5>
            <button className="delete" onClick={this.props.handleClose}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Statistics info of instance - #{this.props.instance.id}</p>

            <h4 className="is-size-4 mb-3">Inputs:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Scrip:</th>
                  <td>{this.props.instance.scrip}</td>
                </tr>
                <tr>
                  <th>Lots:</th>
                  <td>{this.props.instance.lots}</td>
                </tr>
                <tr>
                  <th>Capital:</th>
                  <td>{this.props.instance.capital}</td>
                </tr>
                <tr>
                  <th>Account Balance %:</th>
                  <td>{this.props.instance.accBalancePct}</td>
                </tr>
                <tr>
                  <th>Trading Mode:</th>
                  <td>{this.props.instance.tradingMode}</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h4 className="is-size-4 mb-2">Statistics:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Stopped:</th>
                  <td>{this.props.instance.stopped ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Status:</th>
                  <td>{this.props.instance.status}</td>
                </tr>
                <tr>
                  <th>Entered Qty:</th>
                  <td>{this.props.instance.enteredQty}</td>
                </tr>
                <tr>
                  <th>Entered Price:</th>
                  <td>{this.props.instance.enteredPrice}</td>
                </tr>
                <tr>
                  <th>LTP:</th>
                  <td>{this.props.instance.ltp}</td>
                </tr>
                <tr>
                  <th>Running P&L:</th>
                  <td>{this.props.instance.runningPnl}</td>
                </tr>
                <tr>
                  <th>Booked P&L:</th>
                  <td>{this.props.instance.bookedPnl}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    );
  }
}

export default Strategy1InfoBox;